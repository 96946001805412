<template>
  <div class="groups">
    <div class="groups-wrapper">
      <!-- навигац. цепочка -->
      <div class="nav-chain">
        <div class="chain">
          <div class="link">Группы</div>
          <!-- кнопка вызова модалки создания категории -->
          <v-btn
            color="white"
            class="btn"
            v-show="groups.length > 0"
            @click="openCreateModal"
          >
            <v-img
              alt="login"
              contain
              src="@/assets/plus.svg"
              transition="scale-transition"
              width="37"
            /><span class="btn__text">Добавить</span>
          </v-btn>
        </div>
      </div>
      <!-- пустые карточки для фона -->
      <div class="cards" v-show="groups.length === 0">
        <div class="card-wrapper" v-for="n in 12" :key="n"></div>
      </div>
      <!-- заполненные карточки -->
      <div class="cards" v-if="groups">
        <div class="card-wrapper" v-for="(group, index) in groups" :key="index">
          <group-card
            :name="group.title"
            :id="group.id"
            @edit="openEditModal(group.id, group.title)"
          />
        </div>
      </div>
    </div>
    <!-- если нет категорий созданных -->
    <div class="noCards" v-show="groups.length === 0 && !groupModalShow">
      <div class="text">
        Для добавления первой карточки группы нажмите кнопку “Добавить”
      </div>
      <!-- кнопка вызова модалки создания категории -->
      <v-btn color="white" class="btn" @click="openCreateModal">
        <v-img
          alt="login"
          contain
          src="@/assets/plus.svg"
          transition="scale-transition"
          width="37"
        /><span class="btn__text">Добавить</span>
      </v-btn>
    </div>
    <!-- модалка редактирования или создания категории -->
    <group-modal
      :show="groupModalShow"
      :text="editedName"
      :type="groupModalType"
      @closed="groupModalShow = false"
      @accepted="
        groupModalType == 'create'
          ? createGroup($event)
          : editGroup(editedId, $event)
      "
    />
  </div>
</template>

<script>
import axios from "axios";
import groupModal from "@/components/Groups/groupModal";
import groupCard from "@/components/Groups/groupCard";
export default {
  components: {
    groupModal,
    groupCard,
  },
  data() {
    return {
      groupModalShow: false,
      // create edit
      groupModalType: "create",
      // текст редактируемой карточки в данный момент
      editedName: "",
      editedId: "",

      groups: [],
    };
  },
  methods: {
    openCreateModal() {
      this.editedName = "";
      this.groupModalType = "create";
      this.groupModalShow = true;
    },
    createGroup(data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/groups", {
            title: data,
          })
          .then((resp) => {
            this.groupModalShow = false;
            this.getGroups();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    getGroups() {
      return new Promise((resolve, reject) => {
        axios
          .get("/groups")
          .then((resp) => {
            this.groups = resp.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    openEditModal(id, title) {
      this.editedId = id;
      this.editedName = title;
      this.groupModalType = "edit";
      this.groupModalShow = true;
    },

    editGroup(id, title) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/groups/${id}`, {
            title: title,
          })
          .then((resp) => {
            this.groupModalShow = false;
            this.getGroups();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
  },
  created() {
    this.getGroups();
  },
};
</script>

<style lang="scss" scoped>
.groups {
  position: relative;
  height: 100%;
  &-wrapper {
    padding: 0 140px 0 170px;
    height: 100%;
  }
}
.nav-chain {
  padding-top: 0;
  padding-bottom: 0;
  z-index: 11;
}
.chain {
  padding-right: 28px;
  padding-top: 40px;
  padding-bottom: 58px;
  text-indent: 12px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(54, 66, 165, 0.65);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // height: 100%;
  width: 100%;
  box-sizing: border-box;
  max-height: 100%;
}
.card-wrapper {
  box-sizing: border-box;
  width: 353px;
  height: 157px;
  background: #e9e8e8;
  // background: black;
  border-radius: 5px;
  margin-bottom: 38px;
  margin-right: 22px;
}

.noCards {
  z-index: 10;
  position: absolute;
  width: 100%;
  background: rgba(108, 108, 108, 0.15);
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    max-width: 408px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    margin-bottom: 32px;
  }
  .btn {
    width: 167px;
    height: 47px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #3642a5;
      margin-left: 11px;
    }
  }
}
</style>
