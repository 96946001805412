<template>
  <v-row justify="center">
    <v-dialog
      content-class="dialog"
      max-width="353"
      min-height="193"
      :value="show"
      @click:outside="$emit('closed')"
    >
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-textarea
            rows="3"
            auto-grow
            color="#11ACED"
            outlined
            label="Название группы"
            name="name"
            type="text"
            counter="80"
            :rules="[rules.required, rules.max]"
            v-model="name"
          ></v-textarea>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            style="height: 40px"
            color="black"
            text
            @click="$emit('closed')"
          >
            Отменить
          </v-btn>

          <v-btn class="submin-btn" text @click="submit"> Ок </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["show", "type", "text"],
  data() {
    return {
      valid: false,
      name: "",
      rules: {
        required: (value) => !!value || "Обязательное поле",
        max: (v) => v.length <= 80 || "Максимум 80 символов",
      },
    };
  },
  methods: {
    submit() {
      if (this.name !== "" && this.name.length <= 80) {
        this.$emit("accepted", this.name);
        this.name = "";
        this.$refs.form.reset();
      }
    },
    initName() {
      if (this.type !== "create") {
        this.name = this.text;
      } else {
        this.name = "";
        this.$refs.form.reset();
      }
    },
  },
  created() {
    if (this.text) {
      this.initName();
    }
  },
  watch: {
    text() {
      this.initName();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .v-card {
    width: 353px !important;
    min-height: 193px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 37px 22px 16px 22px;
    &__actions {
      padding: 0 !important;
      margin-top: 17px;
    }
  }
  .submin-btn {
    height: 40px;
    color: white;
    background: #4caf50;
  }
}
</style>
