<template>
  <router-link class="link" :to="{ name: 'Categories', params: { id: id } }">
    <v-card elevation="2" class="card">
      <v-card-actions>
        <v-btn class="btn" text @click.prevent="$emit('edit')"
          ><v-icon size="25" color="rgba(0, 0, 0, 0.5)"
            >mdi-pencil-outline</v-icon
          ></v-btn
        >
      </v-card-actions>
      <div class="text">{{ name }}</div>
    </v-card></router-link
  >
</template>

<script>
export default {
  props: ["name", "id"],
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  height: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.text {
  margin-top: 27px;
  width: 292px;
  height: 38px;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  align-self: center;
  justify-self: center;
  //   2 строки с точками в конце при оверфлове
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  -ms-line-clamp: 2;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;
}

.btn {
  min-width: 32px !important;
  width: 32px !important;
  height: 32px !important;
}
.v-card__actions {
  padding: 0 !important;
  align-self: flex-end;
}
.link {
  text-decoration: none;
}
</style>
